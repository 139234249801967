/* ----------- 視覺設計 區塊 ----------------------------*/
  *,*:before,*:after { box-sizing: border-box; }
  body {
    background: hsl(35, 68%, 98%);
  }
  
  .card {
    color: white;
    box-shadow: 0px 1px 1em hsla(35, 68%, 12%, 0.4);
  }

/* ----------- 動畫製作 ----------------------------*/
  .waterfall01{
    width: 100px;
    height: 160vh;

    background: url(./images/race-logo_01.jpg) no-repeat 0 0;
    background-size: 100% auto;
    animation: waterfall 5s infinite;
    animation-timing-function: ease-in-out;

    position: absolute;
    opacity: 0.6;

    right: 110px ;
    top: 10px;
  }
  .waterfall02{
    width: 100px;
    height: 160vh;

    background: url(./images/race-logo_02.jpg) no-repeat 0 0;
    background-size: 100% auto;
    animation: waterfall 5s infinite;
    animation-timing-function: ease-in-out;

    position: absolute;
    opacity: 0.6;

    right: 0;
    top: 10px;
  }
  @keyframes waterfall{
    0%{
      background-position: 0px 00px;
    }
    50%{
      background-position: 0px 30px;
    }
    100%{
      background-position: 0px 0px;
    }
  }

  /* ----------- card hover 效果 ----------------------------*/
.box {
  transition: all 0.3s ease;
  background-color: #fff;
}

.tag {
  background: var(--light-color);
  border-radius: 20px;
  display: inline-block;
  padding: 4px 16px;
  font-size: 14px;
  margin-bottom: 10px;
}

.type {
  cursor: pointer;
}

.type:hover {
  transform: translateY(-1px);
  -webkit-transform: translateY(-1px);
  -moz-transform: translateY(-1px);
  box-shadow: 0 30px 50px -20px rgba(0, 0, 0, 0.3);
}

/* --- card hover 效果 : icon effect ---*/
.type-link{
  color: var(--high-color);
  text-align: right;

}

.type-link:hover{
  background: transparent;

}

.type-link span{
  padding-left: 8px;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
}
.type-link:hover span{
  padding-left: 12px;
}

  /* ----------- 共用 -----------------------------------------------------------------------*/

  /*--------------- color ------------------*/
  :root {
    --dark-color: #252422;
    --light-color: #CCC5B9;
    --bgc-color: #e7e7e7;
    --high-color: #EB5E28;
  }

  /*--------------- title ------------------*/
  .sub-cate-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sub-cate-title h3 {
    font-size: 32px;
    font-family: 'Exo 2', sans-serif;
  }
  
  .separator {
    background: var(--dark-color);
    width: 150px;
    height: 2px;
  }
